<template>
  <v-row>
    <v-col>
      <v-row justify="center">
        <h2>Request New Test</h2>
      </v-row>
      <v-row justify="center" class="error--text mt-3">{{ error }}</v-row>
      <v-row>
        <v-col>
          <iframe id="cogFrame" :src="'https://www.cognitoforms.com/DAQA1/daqaprojectintakeform'" frameborder="0" height="800" style="width: 100%"></iframe>
        </v-col>
      </v-row>
    </v-col>
  </v-row> 
</template>

<script>
  export default {
    name: 'RequestNewTest',

    mounted () {
      // Load Cognito forms iframe script
      //console.log("mounted start")
      window.Cognito = null // required, otherwise events will not fire properly
      let myScript = document.createElement('script')
      myScript.onload = this.addSubmitListener
      myScript.setAttribute('src', 'https://www.cognitoforms.com/f/iframe.js')
      document.getElementById('cogFrame').appendChild(myScript)
      //console.log("mounted end")
    },

    data () {
      return {
        error: ''
      }
    },

    methods: {
      addSubmitListener () {
        //console.log("addsubmit start")
        //window.Cognito.on('beforeSubmit', this.createTester)
        //console.log("addsubmit end")
      },

      createTester (event) {
        //event.preventDefault()
        // Add this tester to the db
        console.log("createtester start")
        delete event.data.entry.Demographics.Id // added by cognito -- note the capitalization
        event.data.entry.Demographics.GameCode = ''
        this.$store.dispatch('createTester', event.data.entry.Demographics)
        .then((testerID) => {
          // Check if a tester group already exists with this form ID
          this.$store.dispatch('getTesterGroup', event.data.entry.Form.Id)
          .then((grps) => {
            // If it does, add this tester's ID to its list
            if (grps.length > 0) {
              grps[0].testerIDs.push(testerID)
              this.$store.dispatch('updateTesterGroup', grps[0])
              .catch((err) => {
                this.error = err
              })
            // Otherwise, create it
            } else {
              const grp = {
                formID: event.data.entry.Form.Id,
                testerIDs: [ testerID ],
                text: event.data.entry.Form.Name
              }
              this.$store.dispatch('createTesterGroup', grp)
              .catch((err) => {
                this.error = err
              })
            }
          })
          .catch((err) => {
            this.error = err
          })
        })
        .catch((err) => {
          this.error = err
        })
        console.log("createtester end")
      }
    }
  }
</script>

<style scoped>

</style>